<template>
  <component :is="component" />
</template>

<script setup lang="ts">
import {
  LazyDesktopHeaderDefault,
  LazyDesktopHeaderLogoCenter,
  LazyDesktopHeaderLogoLeft,
} from '#components'

const appStore = useAppStore()

const component = computed(() => {
  switch (appStore.merchant.theme.desktop.header.type) {
    case 'logoLeft':
      return LazyDesktopHeaderLogoLeft

    case 'logoCenter':
      return LazyDesktopHeaderLogoCenter

    default:
      return LazyDesktopHeaderDefault
  }
})
</script>
