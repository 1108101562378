<template>
  <component :is="component" />
</template>

<script setup lang="ts">
import {
  LazyDesktopFooterDefault,
  LazyDesktopFooterLogoLeft,
  LazyDesktopFooterLogoCenter,
} from '#components'

const appStore = useAppStore()

const component = computed(() => {
  switch (appStore.merchant.theme.desktop.footer.type) {
    case 'logoLeft':
      return LazyDesktopFooterLogoLeft

    case 'logoCenter':
      return LazyDesktopFooterLogoCenter

    default:
      return LazyDesktopFooterDefault
  }
})
</script>
